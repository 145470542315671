/* eslint-disable prettier/prettier */
import React from 'react';
import { Modal } from '@components';
import { Wrapper, Body, Actions } from './styles';

const Component = ({ title, description, content: Content, ok, cancel, onClose, deleteButton, className='' }) => {
  return (
    <Wrapper>
      <Body>
        {title && <div className={`title ${className}`}>{title}</div>}
        {description && <div className="description">{description}</div>}
        {Content && Content}
      </Body>
      <Actions>
        {ok && (
          <button style={ok.style} onClick={ok.action} className="ok">
            {ok.text}
          </button>
        )}

        {deleteButton && (
          <button style={deleteButton.style} onClick={deleteButton.action} className="deleteButton">
            {deleteButton.text}
          </button>
        )}
        <button style={cancel?.style} onClick={cancel?.action || onClose} className="close">
          {cancel?.text || 'Cancel'}
        </button>
      </Actions>
    </Wrapper>
  );
};

const Confirm = (props) => {
  return (
    <Modal
      animation="fade"
      overlayStyle={{
        backgroundColor: 'var(--overlay)',
      }}
      style={{
        width: 'min(90%, 470px)',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'var(--theme-solid)',
        borderRadius: 12,
        backdropFilter: 'blur(20px) invert(0.15)',
      }}
      component={Component}
      {...props}
    />
  );
};

export default Confirm;
