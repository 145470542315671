/* eslint-disable prettier/prettier */
import styled from 'styled-components/macro';
import { COLORS } from 'theme';

export const OuterShadow = styled.div`
  background: ${COLORS.theme_gradient};
  ${'' /* position: fixed; */}
  width: 100%;
  padding: 2px;
  --border: 1px;
  --bordercol: transparent;
  --bgcolor: ${COLORS.theme_gradient};
  --top-right: 2rem;
  border: var(--border) solid var(--bordercol);
  background: linear-gradient(
        330deg,
        var(--bordercol) 0 calc(var(--top-right) + var(--border)),
        transparent 0
      )
      bottom right,
    var(--bgcolor);
  background-repeat: no-repeat;
  -webkit-mask: linear-gradient(0deg, transparent 0 0, white 0) bottom left,
    linear-gradient(0deg, transparent 0 0, white 0) top right,
    linear-gradient(0deg, transparent 0 0, white 0) top left,
    linear-gradient(320deg, transparent 0 var(--top-right), white 0) bottom right;
  -webkit-mask-size: 50.5% 50.5%;
  -webkit-mask-repeat: no-repeat;
  @media only screen and (min-width: 280px) and (max-width: 600px) {
    width: 95%;
    padding: 1px;
  }
`;

export const InnerShadow = styled.div`
  background: ${COLORS.theme_color};
  padding: 5px;
  --border: 0.1rem;
  width: 100%;
  --bordercol: black;
  --bgcolor: #000000;
  --top-right: 2rem;
  border: var(--border) solid var(--bordercol);
  background: linear-gradient(
        330deg,
        var(--bordercol) 0 calc(var(--top-right) + var(--border)),
        transparent 0
      )
      bottom right,
    var(--bgcolor);
  background-repeat: no-repeat;
  -webkit-mask: linear-gradient(0deg, transparent 0 0, white 0) bottom left,
    linear-gradient(0deg, transparent 0 0, white 0) top right,
    linear-gradient(0deg, transparent 0 0, white 0) top left,
    linear-gradient(320deg, transparent 0 var(--top-right), white 0) bottom right;
  -webkit-mask-size: 50.5% 50.5%;
  -webkit-mask-repeat: no-repeat;
  @media only screen and (min-width: 280px) and (max-width: 600px) {
    padding: 1px;
    --border: 1px;
  }
`;

export const OuterWrapper = styled.div`
  background: ${COLORS.theme_gradient};
  position: relative;
  width: 100%;
  padding: 2px;
  --border: 1px;
  --bordercol: transparent;
  --bgcolor: ${COLORS.theme_gradient};
  --top-right: 2rem;
  border: var(--border) solid var(--bordercol);
  background: linear-gradient(
        330deg,
        var(--bordercol) 0 calc(var(--top-right) + var(--border)),
        transparent 0
      )
      bottom right,
    var(--bgcolor);
  background-repeat: no-repeat;
  -webkit-mask: linear-gradient(0deg, transparent 0 0, white 0) bottom left,
    linear-gradient(0deg, transparent 0 0, white 0) top right,
    linear-gradient(0deg, transparent 0 0, white 0) top left,
    linear-gradient(320deg, transparent 0 var(--top-right), white 0) bottom right;
  -webkit-mask-size: 50.5% 50.5%;
  -webkit-mask-repeat: no-repeat;
  @media only screen and (min-width: 280px) and (max-width: 600px) {
    padding: 1px;
  }
`;

export const InnerWrapper = styled.div`
  background: ${COLORS.theme_color};
  display: flex;
  flex-direction: column;
  font-size: 24px;
  width: 100%;
  --border: 1px;
  --bordercol: transparent;
  --bgcolor: #000000;
  --top-right: 2rem;
  border: var(--border) solid var(--bordercol);
  background: linear-gradient(
        330deg,
        var(--bordercol) 0 calc(var(--top-right) + var(--border)),
        transparent 0
      )
      bottom right,
    var(--bgcolor);
  background-repeat: no-repeat;
  -webkit-mask: linear-gradient(0deg, transparent 0 0, white 0) bottom left,
    linear-gradient(0deg, transparent 0 0, white 0) top right,
    linear-gradient(0deg, transparent 0 0, white 0) top left,
    linear-gradient(320deg, transparent 0 var(--top-right), white 0) bottom right;
  -webkit-mask-size: 50.5% 50.5%;
  -webkit-mask-repeat: no-repeat;
  @media only screen and (min-width: 280px) and (max-width: 600px) {
    width: 100%;
  }
  &.help_wrapper {
    min-height: calc(100vh - 250px);
  }
`;
