/* eslint-disable prettier/prettier */
import { useSelector, useDispatch } from 'react-redux';
import useLoader from '@containers/sharedHooks/useLoader';
import { UserAPI } from '@libs/api/user.api';

const useHooks = () => {
  const { isLoading } = useLoader();
  const dispatch = useDispatch();
  const { userInfo, userAllData } = useSelector((state) => state.SharedStore);

  const updateProfile = async (payload) => {
    isLoading(true);
    const res = await UserAPI.updateProfileInfo(payload, userInfo.id);
    isLoading(false);
    if (res.success) {
      dispatch({
        type: 'USER',
        payload: res.success,
      });
    }
    return res;
  };

  const getUserSystemId = async () => {
    isLoading(true);
    const res = await UserAPI.getUserSystemId();
    isLoading(false);
    return res
  };

  const getUserDetail = async () => {
    isLoading(true);
    const res = await UserAPI.getProfileInfo({}, userInfo.id);
    isLoading(false);
    return res;
  };

  const updatePassword = async (payload) => {
    isLoading(true);
    const res = await UserAPI.changePassword(payload);
    isLoading(false);

    return res;
  };

  const logout = async () => {
    isLoading(true);
    const res = await UserAPI.logout();
    localStorage.clear();

    dispatch({
      type: 'USER',
      payload: {},
    });

    isLoading(false);

    return res;
  };

  const deleteUser = async () => {
    isLoading(true);
    const res = await UserAPI.deleteUser(userInfo.id);
    localStorage.clear();
    console.log({ res });

    dispatch({
      type: 'USER',
      payload: {},
    });

    isLoading(false);
  };

  return {
    updateProfile,
    userInfo,
    userAllData,
    updatePassword,
    logout,
    deleteUser,
    getUserSystemId,
    getUserDetail,
  };
};

export default useHooks;
