/* eslint-disable prettier/prettier */
import { getRequest, postRequest, putRequest } from './methods';

const getProfileInfo = async (payload, id) => {
  return await getRequest(`users/${id}/`, payload);
};
const updateProfileInfo = async (payload, id) => {
  return await putRequest(`users/${id}/`, payload);
};
const changePassword = async (payload, id) => {
  return await postRequest(`users/change_password/`, payload);
};

const feedback = async (payload) => {
  return await postRequest(`feedback/`, payload);
};

const logout = async () => {
  return await postRequest(`users/logout/`);
};

const deleteUser = async (id) => {
  return await postRequest(`users/${id}/deactivate/`);
};

const getArea = async (payload) => {
  return await getRequest(`area/`, payload);
};

const getCity = async (id) => {
  return await getRequest(`get_cities/?area_id=${id}`);
};

const getUserSystemId = async () => {
  return await getRequest('user-system-id/')
}

export const UserAPI = {
  getProfileInfo,
  updateProfileInfo,
  changePassword,
  feedback,
  logout,
  deleteUser,
  getArea,
  getCity,
  getUserSystemId,
};
