/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { COLORS } from 'theme';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  font-size: 34px;
  font-weight: 400;
  font-family: 'Pilot Command Laser Super-Ital';
  color: white;
  height: 12vh;
  .back-arrow {
    height: 30px;
    width: 30px;
    position: fixed;
    left: 360px;
  }
  @media only screen and (min-width: 280px) and (max-width: 767px) {
    font-size: 1.2em;
    .back-arrow {
      left: 310px;
    }
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 88vh;
`;
export const CardContainer = styled.div`
  width: 100%;
  margin: 1rem;
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem 1rem;
`;
export const InnerCard = styled.div`
  padding: 2rem;
`;
export const CardHeader = styled.div`
  padding-bottom: 1rem;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(16, 210, 250, 0.22);
  .heading {
    font-size: 32px;
    font-weight: 700;
    font-family: 'Montserrat';
  }
  button {
    padding: 0.5rem 1rem;
    background: ${COLORS.theme_gradient};
    font-family: 'Orbitron';
    font-size: 16px;
    font-weight: 500;
  }
  @media only screen and (min-width: 280px) and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const CardBody = styled.div`
  border-bottom: 1px solid rgba(16, 210, 250, 0.22);
  padding: 1rem 0;
  color: ${({ disable }) => (disable ? 'rgba(21, 174, 219, 0.5)' : 'rgba(21, 174, 219, 1)')};
  font-size: 24px;
  font-weight: 500;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    margin-left: 2rem;
  }

  img {
    max-width: 100px;
  }
`;
export const InputWrapper = styled.div`
  color: white;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  padding: 0.5rem;
  width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    margin: 0;
  }

  .all_input {
    width: 100%;
    border: 1px solid rgba(16, 210, 250, 1);
    margin: 0 0 10px 0;
    padding: 10px;
  }
  
  .all_input.all_donationInput .select-placeholder {
    opacity: 1;
  }
  .areaspace {
    border: 1px solid rgba(16, 210, 250, 1);
    padding: 0px 10px 10px 10px;
  }
  .txtarea {
    width: 100%;
    border: none;
    background-color: black;
    color: white;
  }
  .txtarea-description {
    width: 100%;
    border: none;
    background-color: black;
    color: white;
    height: 200px;
  }
`;

export const ItemFieldError = styled.div`
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  color: var(--error);
  text-align: start;
  padding-bottom: 10px;

  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 9px;
  }
`;
