/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  font-family: 'Montserrat';
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 0;
  justify-content: center;
  font-size: 34px;
  font-weight: 400;
  font-family: 'Pilot Command Laser Super-Ital';
  color: white;
  height: 12vh;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 88vh;
  padding: 10px 31px 10px 75px;
`;
export const CardContainer = styled.div`
  .card-img {
    height: auto;
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  .help-content-section {
    margin-top: 36px;
    .help-no-data {
      color: rgba(23, 197, 247, 1);
      text-align: center;
      font-size: 34px;
      font-weight: bold;
      line-height: calc(100vh - 350px);
    }
  }
  .help-content-wrapper {
    padding: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    margin: 8px 58px;
    border-bottom: 0.5px rgba(16, 210, 250, 0.22) solid;
    border-top: none;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    &:first-of-type {
      border-top: 0.5px rgba(16, 210, 250, 0.22) solid;
    }
    .help {
      min-width: 470px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-top: 7px;
      @media (max-width: 1300px) {
        min-width: 300px;
      }
      .help-url-img {
        width: 176px;
        height: 105px;
        padding-bottom: 16px;
      }
      .help-url {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #15aedb !important;
        font-size: 16px;
        font-family: Montserrat;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.38px;
        word-wrap: break-word;
        a {
          color: #15aedb;
          text-decoration: none;
        }
      }
      .link-divider {
        width: 320px;
        height: 0px;
        border: 0.5px #626262 solid;
        @media (max-width: 1300px) {
          width: 250px;
        }
      }
      .support-link {
        padding-top: 4px;
        color: #b1b1b1;
        font-size: 12px;
        font-family: Montserrat;
        font-weight: 400;
        line-height: 18px;
        word-wrap: break-word;
        @media (max-width: 1000px) {
          padding-bottom: 20px;
        }
      }
    }
    .help-desc {
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px;
      font-family: Poppins;
      font-weight: 400;
      line-height: 18px;
      word-wrap: break-word;
    }
  }
`;

export const ParagraphWrapper = styled.div`
  color: white;
  width: 100%;
  display: flex;
  font-family: 'Montserrat';
  flex-direction: column;
  height: 100vh;
`;
