/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  font-family: 'Montserrat';
`;
export const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 4rem;
`;
export const ListContainer = styled.ul`
  list-style: none;
  max-height: calc(100vh - 300px);
  overflow: auto;
  li {
    margin-bottom: 3vh;
    margin-left: 0px !important;
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
  }
`;
export const ElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  ${({ active }) =>
    active
      ? css`
          color: #17c5f7;
        `
      : css`
          color: #646776;
        `}
`;
export const ProfileContainer = styled.div`
  border-top: 1px solid #15171d;
  border-bottom: 1px solid #15171d;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  ${({ active }) =>
    active
      ? css`
          color: #17c5f7;
        `
      : css`
          color: white;
        `}
  .imgContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #13171f;
    margin-right: 1rem;
  }
  .profile {
    display: flex;
    flex-direction: column;
  }
  .name {
    font-size: 20px;
    font-weight: 500;
  }
  .myprofile {
    font-size: 14px;
    font-weight: 400;
  }
`;
