/* eslint-disable prettier/prettier */
import React from 'react';
import {
  useSelector, //  useDispatch
  connect,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import pages from './pages';
import { Wrapper } from './styles';

const Home = (props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.SharedStore);
  const [page, setPage] = React.useState(props.page || 'home');
  const Component = pages[page.id || page];

  React.useEffect(() => {
    if (!userInfo.id) {
      localStorage.clear();
      navigate('/auth');
    }
  }, [navigate, userInfo]);
  if (!userInfo) return <></>;
  return (
    <Wrapper>
      <Component setPage={setPage} {...page} page={page.id || page} />
    </Wrapper>
  );
};

export default connect()(Home);
