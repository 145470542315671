/* eslint-disable prettier/prettier */
import React from 'react';
import { useState } from 'react';
import { DropDown, Layout, Spinner } from '@components';
import { Confirm } from '@components';
import { InstructionContainer } from '@containers/Auth/sign-up/styles';
import Card from '@containers/screens/card';
import { PAYMENT_METHOD } from '@containers/screens/profile/data';
import { ArrowBackSvg } from '@svgs';
import { requiredValidator } from '../../../../utils/validators';
import useHooks from '../useHooks';
// import RocketFuel from '../rocketfuel';
import {
  Header,
  CardWrapper,
  MainWrapper,
  Wrapper,
  CardContainer,
  CardHeader,
  InnerCard,
  CardBody,
  InputWrapper,
  ItemFieldError,
} from './styles';

const Room = ({ data, setPage, page }) => {
  const [show, setShow] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showRedeem, setShowRedeem] = useState(false);
  const [redeemData, setRedeemData] = useState({
    amount: '',
    system: '',
    memo: '',
    payment_method: '',
  });
  const [showSendMail, setShowSendMail] = useState(false);
  const [sendMailData, setSendMailData] = useState({
    subject: '',
    description: '',
  });
  const [userInput, setUserInput] = useState({});
  const [roomSystem, setRoomSystem] = useState([]);
  const [coinSystem, setCoinSystem] = useState([]);
  const [methodsName, setMethodsName] = useState([]);
  const [userInfoError, setUserInfoError] = useState({});
  const [selectedAreaImg, setSelectedAreaImg] = useState(
    'https://slyod-designs-37273.s3.amazonaws.com/media/area_images/default.jpg'
  );
  const [imageLoading, setImageLoading] = useState(true);
  const {
    getSingleRoom,
    singleRooms,
    createDonation,
    createRedeem,
    getCryptoCoin,
    cryptoCoin,
    getPaymentMethods,
    paymentMethod,
    sendMail,
  } = useHooks();
  const onClose = () => {
    setShowPopup(false);
    setShow(false);
    setShowRedeem(false);
    setImageLoading(false);
    setShowSendMail(false);
    setSendMailData({
      subject: '',
      description: '',
    });
    setShowSendMail(false);
    setSelectedAreaImg(
      'https://slyod-designs-37273.s3.amazonaws.com/media/area_images/default.jpg'
    );
    setUserInput({
      ...userInput,
      amount: '',
      memo: '',
      system: '',
      systemID: '',
      payment_method: '',
      coin: '',
      crypto_network_name: '',
      customer_wallet_address: '',
      crypto_wallet_number: '',
    });
    setUserInfoError({});
  };
  React.useEffect(() => {
    const coinData = [];
    if (cryptoCoin.length > 0) {
      cryptoCoin.map((val) => {
        coinData.push({
          title: val.coin_name,
          value: val.id,
          image: val.qr_code,
          coin_network: val.coin_network,
          wallet_address: val.wallet_address,
        });
      });
      setCoinSystem(coinData);
    }
  }, [cryptoCoin]);

  React.useEffect(() => {
    const paymentData = [];
    if (paymentMethod.length > 0) {
      paymentMethod.map((val) => {
        paymentData.push({
          label: val.payment_method_name,
          value: val.payment_method_name,
        });
      });
      setMethodsName(paymentData);
    }
  }, [paymentMethod]);

  const roomInfo = data?.element;
  React.useEffect(() => {
    if (roomInfo) {
      const dropdownSystem = [];
      getSingleRoom(roomInfo.id);
      getCryptoCoin(roomInfo.id);
      getPaymentMethods(roomInfo.id);
      // eslint-disable-next-line array-callback-return
      roomInfo.systems.map((val) => {
        dropdownSystem.push({
          title: val.title,
          value: val.id,
          id: val.system_id ? val.system_id : '',
        });
      });
      setRoomSystem(dropdownSystem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomInfo]);

  if (!singleRooms) return <Spinner />;

  const onTextChange = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  };

  const onMailTextChange = (e) => {
    const { name, value } = e.target;
    setSendMailData({ ...sendMailData, [name]: value });
  };

  const onRedeemTextChange = (e) => {
    const { name, value } = e.target;
    setRedeemData({ ...redeemData, [name]: value });
  };

  const handlePay = async () => {
    if (validation()) {
      setShowPopup(true);
    }
  };

  const payOnClick = async () => {
    if (!validation()) {
      //validation failed
      return;
    }

    // let redirect_url = window.location.href;
    // redirect_url = redirect_url.replace('home', '');
    // redirect_url = `${redirect_url}rocketFuel`;
    const payload = {
      amount: userInput.amount,
      room_id: roomInfo.id,
      memo: userInput.memo,
      system_id: userInput.system.value,
      crypto_coin_name: userInput.coin.title,
      crypto_network: userInput.crypto_network_name,
      customer_wallet_address: userInput.customer_wallet_address,
      customer_wallet_no: userInput.crypto_wallet_number,
    };
    await createDonation(payload);
    setShowPopup(false);
    setSelectedAreaImg(
      'https://slyod-designs-37273.s3.amazonaws.com/media/area_images/default.jpg'
    );
    setShow(false);
    setUserInput({});
    setUserInfoError({});
    // setRoomSystem([])
  };

  const redeemOnClick = async () => {
    if (!validation()) {
      //validation failed
      return;
    }

    const payload = {
      amount: redeemData.amount,
      room_id: roomInfo.id,
      memo: redeemData.memo,
      system_id: redeemData.system.value,
      payment_method: redeemData.payment_method.value,
    };
    await createRedeem(payload);

    setShowRedeem(false);
    setRedeemData({
      amount: '',
      system: '',
      memo: '',
      payment_method: '',
    });
    setUserInput({});
    setUserInfoError({});
    // setRoomSystem([])
  };

  const sendMailOnClick = async () => {
    if (!validation()) {
      //validation failed
      return;
    }

    const payload = {
      subject: sendMailData.subject,
      description: sendMailData.description,
      room_id: roomInfo.id,
    };
    await sendMail(payload);

    setShowSendMail(false);
    setUserInput({});
    setUserInfoError({});
    setSendMailData({
      subject: '',
      description: '',
    });
  };

  const areaImgLoaded = () => {
    setImageLoading(false);
  };

  const onDropdownSelect = (name, item) => {
    setUserInput({
      ...userInput,
      [name]: item,
      crypto_network_name: item.coin_network,
      customer_wallet_address: item.wallet_address,
    });
    if (item.image) {
      setSelectedAreaImg(item.image);
      setImageLoading(true);
    }
  };

  const newOnDropdownSelect = (name, item) => {
    setRedeemData({
      ...redeemData,
      [name]: item,
      crypto_network_name: item.coin_network,
      customer_wallet_address: item.wallet_address,
    });
    if (item.image) {
      setSelectedAreaImg(item.image);
      setImageLoading(true);
    }
  };

  const validation = () => {
    const errors = {};
    let validation = true;

    if (show) {
      if (requiredValidator(userInput.memo)) {
        errors.memo = requiredValidator(userInput.memo, 'Memo');
        validation = false;
      }

      if (requiredValidator(userInput.system)) {
        errors.system = requiredValidator(userInput.system, 'System');
        validation = false;
      }

      if (requiredValidator(userInput.coin)) {
        errors.coin = requiredValidator(userInput.coin, 'Coin field');
        validation = false;
      }

      if (requiredValidator(userInput.crypto_network_name)) {
        errors.crypto_network_name = requiredValidator(
          userInput.crypto_network_name,
          'Crypto Network Name'
        );
        validation = false;
      }

      if (requiredValidator(userInput.crypto_wallet_number)) {
        errors.crypto_wallet_number = requiredValidator(
          userInput.crypto_wallet_number,
          'User Crypto Wallet Number'
        );
        validation = false;
      }

      if (requiredValidator(userInput.customer_wallet_address)) {
        errors.customer_wallet_address = requiredValidator(
          userInput.customer_wallet_address,
          'User Crypto Wallet Address'
        );
        validation = false;
      }
      if (requiredValidator(userInput.amount)) {
        errors.amount = requiredValidator(userInput.amount, 'Amount');
        validation = false;
      }
    }

    if (showRedeem) {
      if (requiredValidator(redeemData.amount)) {
        errors.amount = requiredValidator(redeemData.amount, 'Amount');
        validation = false;
      }
      if (requiredValidator(redeemData.system)) {
        errors.system = requiredValidator(redeemData.system, 'System');
        validation = false;
      }
      if (requiredValidator(redeemData.payment_method)) {
        errors.payment_method = requiredValidator(redeemData.payment_method, 'Payment Method');
        validation = false;
      }
      if (requiredValidator(redeemData.memo)) {
        errors.memo = requiredValidator(redeemData.memo, 'Memo');
        validation = false;
      }
    }

    if (showSendMail) {
      if (requiredValidator(sendMailData.subject)) {
        errors.subject = requiredValidator(sendMailData.subject, 'Subject');
        validation = false;
      }
      if (requiredValidator(sendMailData.description)) {
        errors.description = requiredValidator(sendMailData.description, 'Description');
        validation = false;
      }
    }

    if (!validation) {
      setUserInfoError(errors);
    }

    return validation;
  };

  return (
    <Layout setPage={setPage} page={page}>
      <MainWrapper>
        <Header>
          <ArrowBackSvg className="back-arrow" onClick={() => setPage({ id: 'home' })} />
          <span>{roomInfo?.title}</span>
        </Header>
        <Wrapper>
          <CardWrapper>
            <CardContainer>
              <Card>
                <InnerCard>
                  <CardHeader>
                    <div className="heading">{'Systems'}</div>
                    <div style={{ justifyContent: 'flex-end' }}>
                      <button style={{ marginRight: 30 }} onClick={() => setShow(true)}>
                        Make Donation
                      </button>
                      <button style={{ marginRight: 30 }} onClick={() => setShowRedeem(true)}>
                        Redeem
                      </button>
                      <button onClick={() => setShowSendMail(true)}>Send Mail</button>
                    </div>
                    <Confirm
                      hideCloseButton={true}
                      visible={show}
                      title="Donation Amount"
                      onClose={onClose}
                      ok={
                        userInput.amount > 0 && {
                          text: 'Pay',
                          action: handlePay,
                        }
                      }
                      content={
                        <div style={{ overflow: 'scroll', height: '60vh' }}>
                          <InputWrapper>
                            <div className="all_input">
                              <div className="header">
                                <span>Amount</span>
                              </div>
                              <input
                                name="amount"
                                placeholder=""
                                type="number"
                                autoComplete="off"
                                style={{ width: '100%' }}
                                onChange={onTextChange}
                                value={userInput.amount}
                              />
                            </div>
                            <div className="all_input all_donationInput">
                              <DropDown
                                width="100%"
                                onChange={(e) => onDropdownSelect('system', e)}
                                style={{
                                  textAlign: 'left',
                                  justifyContent: 'space-between',
                                  fontSize: '16px',
                                  color: '#fff',
                                }}
                                getLabel={(o) => o.title}
                                name="system"
                                type="dropdown"
                                placeholder="System"
                                value={userInput.system}
                                options={roomSystem}
                              />
                            </div>
                            {userInfoError['system'] && (
                              <ItemFieldError>{userInfoError['system']}</ItemFieldError>
                            )}
                            {userInput.system && (
                              <div className="all_input">
                                <input
                                  name="systemID"
                                  placeholder=""
                                  disabled
                                  type="number"
                                  autoComplete="off"
                                  style={{ width: '100%' }}
                                  onChange={onTextChange}
                                  value={userInput?.system?.id ? userInput.system.id : '-'}
                                />
                              </div>
                            )}
                            <div className="areaspace">
                              <div className="header" style={{ marginTop: '10px' }}>
                                <span>Memo</span>
                              </div>
                              <textarea
                                className="txtarea"
                                value={userInput.memo}
                                onChange={onTextChange}
                                name="memo"
                              />
                            </div>
                            {userInfoError['memo'] && (
                              <ItemFieldError>{userInfoError['memo']}</ItemFieldError>
                            )}
                            <div
                              className="all_input all_donationInput"
                              style={{ marginTop: '10px' }}
                            >
                              <DropDown
                                width="100%"
                                onChange={(e) => {
                                  onDropdownSelect('coin', e);
                                }}
                                style={{
                                  textAlign: 'left',
                                  justifyContent: 'space-between',
                                  fontSize: '16px',
                                  color: '#fff',
                                }}
                                getLabel={(o) => o.title}
                                name="coin"
                                type="dropdown"
                                placeholder="Select Coin"
                                value={userInput.coin}
                                options={coinSystem}
                              />
                            </div>
                            {userInfoError['coin'] && (
                              <ItemFieldError>{userInfoError['coin']}</ItemFieldError>
                            )}
                            <div className="areaspace">
                              <div className="header" style={{ marginTop: '10px' }}>
                                <span>Crypto Network Name:</span>
                              </div>
                              <input
                                style={{ color: 'gray' }}
                                className="txtarea"
                                value={userInput.crypto_network_name}
                                onChange={onTextChange}
                                name="crypto_network_name"
                                readOnly
                              />
                            </div>
                            {userInfoError['crypto_network_name'] && (
                              <ItemFieldError>
                                {userInfoError['crypto_network_name']}
                              </ItemFieldError>
                            )}
                            <div className="areaspace" style={{ marginTop: '10px' }}>
                              <div className="header" style={{ marginTop: '10px' }}>
                                <span>Crypto Wallet Address for Donation:</span>
                              </div>
                              <input
                                style={{ color: 'gray' }}
                                className="txtarea"
                                value={userInput.customer_wallet_address}
                                onChange={onTextChange}
                                name="customer_wallet_address"
                                readOnly
                              />
                            </div>
                            {userInfoError['customer_wallet_address'] && (
                              <ItemFieldError>
                                {userInfoError['customer_wallet_address']}
                              </ItemFieldError>
                            )}
                            {imageLoading && <Spinner size="small" />}
                            <img
                              src={selectedAreaImg}
                              style={{ height: '274px', width: '100%', margin: '20px 0 20px 0' }}
                              alt={'alt'}
                              onLoad={areaImgLoaded}
                            />
                            <div className="areaspace" style={{ marginTop: '10px' }}>
                              <div className="header" style={{ marginTop: '10px' }}>
                                <span>Enter your Crypto Wallet Number:</span>
                              </div>
                              <input
                                className="txtarea"
                                value={userInput.customer_wallet_no}
                                onChange={onTextChange}
                                name="crypto_wallet_number"
                              />
                            </div>
                            {userInfoError['crypto_wallet_number'] && (
                              <ItemFieldError>
                                {userInfoError['crypto_wallet_number']}
                              </ItemFieldError>
                            )}
                            <p
                              style={{
                                color: 'red',
                                fontSize: '15px',
                                marginTop: '10px',
                                marginBottom: '0',
                              }}
                            >
                              IMPORTANT PLEASE READ INSTRUCTIONS!
                            </p>
                            <p style={{ fontSize: '12px', textAlign: 'justify', padding: '10px' }}>
                              Please donate the crypto coins from you crypto wallet of choice by
                              using Crypto Wallet Address provided above or by scanning the QR code
                              within your crypto wallet. Please note that only the supported Crypto
                              Network written above will work so donate using that one. If you
                              donate via another network your donation will be lost and outside of
                              possibility from us to help you. Conversion to USD is done once per
                              day so we can register your donation at some moment in the next 24
                              hours. We do not take any commission, fees are only what crypto
                              exchange charges. When you finish the transaction please enter your
                              Wallet Number from which you donated in the field above and press the
                              Pay button. This is so we can find your donation.
                            </p>
                          </InputWrapper>
                        </div>
                      }
                    />
                    <Confirm
                      visible={showPopup}
                      title="Attention!"
                      className={'red'}
                      onClose={() => setShowPopup(false)}
                      ok={{
                        text: 'Confirm Pay',
                        action: payOnClick,
                      }}
                      content={
                        <div>
                          <p style={{ textAlign: 'justify', padding: '20px' }}>
                            Be sure that in the previous screen you entered the correct wallet
                            number you used to send crypto. If you confirm below and the wallet
                            number is not correct, and the crypto network used to send coins was not
                            correct, we will not be able to find your transaction.
                          </p>
                          <p style={{ textAlign: 'center', padding: '20px' }}>
                            Are you sure you want to continue?
                          </p>
                        </div>
                      }
                    />
                    <Confirm
                      hideCloseButton={true}
                      visible={showRedeem}
                      title="Redeem"
                      onClose={onClose}
                      ok={{
                        text: 'Request Redeem',
                        action: redeemOnClick,
                      }}
                      content={
                        <div style={{ overflow: 'scroll', maxHeight: 'calc(100vh - 300px)' }}>
                          <InputWrapper>
                            <div className="all_input">
                              <div className="header">
                                <span>Amount</span>
                                <span>USD</span>
                              </div>
                              <input
                                name="amount"
                                placeholder=""
                                type="number"
                                autoComplete="off"
                                style={{ width: '100%' }}
                                onChange={onRedeemTextChange}
                                value={redeemData.amount}
                              />
                            </div>
                            {userInfoError['amount'] && (
                              <ItemFieldError>{userInfoError['amount']}</ItemFieldError>
                            )}
                            <div className="all_input all_donationInput">
                              <DropDown
                                width="100%"
                                onChange={(e) => newOnDropdownSelect('system', e)}
                                style={{
                                  textAlign: 'left',
                                  justifyContent: 'space-between',
                                  fontSize: '16px',
                                  color: '#fff',
                                }}
                                getLabel={(o) => o.title}
                                name="system"
                                type="dropdown"
                                placeholder="System"
                                value={redeemData.system}
                                options={roomSystem}
                              />
                            </div>
                            {userInfoError['system'] && (
                              <ItemFieldError>{userInfoError['system']}</ItemFieldError>
                            )}
                            <div className="all_input all_donationInput">
                              <DropDown
                                width="100%"
                                onChange={(e) => newOnDropdownSelect('payment_method', e)}
                                style={{
                                  textAlign: 'left',
                                  justifyContent: 'space-between',
                                  fontSize: '16px',
                                  color: '#fff',
                                }}
                                getLabel={(o) => o.label}
                                name="payment_method"
                                type="dropdown"
                                placeholder="Payment method"
                                value={redeemData.payment_method}
                                options={methodsName}
                              />
                            </div>
                            {userInfoError['payment_method'] && (
                              <ItemFieldError>{userInfoError['payment_method']}</ItemFieldError>
                            )}
                            <div className="areaspace">
                              <div className="header" style={{ marginTop: '10px' }}>
                                <span>Memo</span>
                              </div>
                              <textarea
                                className="txtarea"
                                value={redeemData.memo}
                                onChange={onRedeemTextChange}
                                name="memo"
                              />
                            </div>
                            {userInfoError['memo'] && (
                              <ItemFieldError>{userInfoError['memo']}</ItemFieldError>
                            )}
                            <InstructionContainer>
                              In Memo field please fill in all the needed details for selected
                              payment method and the reason for request. If it’s Revolut add rev
                              tag, Paypal add username/email.
                            </InstructionContainer>
                          </InputWrapper>
                        </div>
                      }
                    />
                    {/* ______________________________ */}
                    {/* Modal for Send Mail Button */}
                    {/* ______________________________ */}
                    <Confirm
                      hideCloseButton={true}
                      visible={showSendMail}
                      title="Need Help? - Send a Message"
                      onClose={onClose}
                      ok={{
                        text: 'Send',
                        action: sendMailOnClick,
                      }}
                      content={
                        <div style={{ overflow: 'scroll', maxHeight: 'calc(100vh - 300px)' }}>
                          <InputWrapper>
                            <div className="all_input">
                              <div className="header">
                                <span>Subject</span>
                              </div>
                              <input
                                name="subject"
                                placeholder=""
                                type="text"
                                autoComplete="off"
                                style={{ width: '100%' }}
                                onChange={onMailTextChange}
                                value={sendMailData.subject}
                              />
                            </div>
                            {userInfoError['subject'] && (
                              <ItemFieldError>{userInfoError['subject']}</ItemFieldError>
                            )}
                            <div className="areaspace">
                              <div className="header" style={{ marginTop: '10px' }}>
                                <span>Description</span>
                              </div>
                              <textarea
                                className="txtarea-description"
                                value={userInput.description}
                                onChange={onMailTextChange}
                                name="description"
                              />
                            </div>
                            {userInfoError['description'] && (
                              <ItemFieldError>{userInfoError['description']}</ItemFieldError>
                            )}
                            <InstructionContainer>
                              We will answer you as soon as we can. You will receive response to
                              your registered email, as well as copy of this message you sent.
                            </InstructionContainer>
                          </InputWrapper>
                        </div>
                      }
                    />
                    {/* _________________________________ */}
                    {/* End of Modal for Send Mail Button */}
                    {/* _________________________________ */}
                  </CardHeader>
                  {singleRooms?.systems?.map((element, index) => {
                    const url = element?.url;
                    return (
                      <CardBody key={index} disable={!url}>
                        <img src={element?.image} alt="" />
                        <span onClick={() => url && window.open(element?.url)}>
                          {element?.title}
                        </span>
                      </CardBody>
                    );
                  })}
                </InnerCard>
              </Card>
            </CardContainer>
          </CardWrapper>
        </Wrapper>
      </MainWrapper>
    </Layout>
  );
};

export default Room;
