/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { sortBy } from 'lodash';
import useLoader from '@containers/sharedHooks/useLoader';
import { PlayerAPI } from '@libs/api';
import RocketFuel from './rocketfuel';

const useHooks = () => {
  const { isLoading } = useLoader();
  const [allRooms, setAllRooms] = useState();
  const [singleRooms, setSingleRooms] = useState();
  const [cryptoCoin, setCryptoCoin] = useState([]);
  const [paymentMethod, setPaymentMethods] = useState([]);

  // const rocketfuelInit = async (uuid) => {
  //   const rkfl = new RocketFuel({
  //     uuid,
  //     callback: (res) => console.log({ res }),
  //     environment: 'prod', // prod, preprod
  //   });
  //
  //   rkfl.initPayment();
  // };

  const getAllRooms = async () => {
    isLoading(true);
    const res = await PlayerAPI.getAllRooms();
    isLoading(false);

    if (res) {
      let sorted = sortBy(res, (o) => o.order);
      setAllRooms(sorted);
    }
  };

  const getSingleRoom = async (id) => {
    isLoading(true);
    const res = await PlayerAPI.getSingleRoom(id);
    isLoading(false);
    if (res) {
      setSingleRooms(res);
    }
  };

  const createDonation = async (payload) => {
    isLoading(true);
    const res = await PlayerAPI.createDonation(payload);

    // if (res.success?.rf_transaction_id) {
    //   rocketfuelInit(res.success?.rf_transaction_id);
    // }
    isLoading(false);
    return true;
  };

  const createRedeem = async (payload) => {
    isLoading(true);
    await PlayerAPI.createRedeem(payload);
    isLoading(false);
    return true;
  };

  const handleCallback = async (id) => {
    isLoading(true);
    const res = await PlayerAPI.updateTransation(id);
    if (res.success) {
      window.location.href = '/home';
    }
    isLoading(false);
    return true;
  };

  const getCryptoCoin = async (id) => {
    isLoading(true);
    const res = await PlayerAPI.getCryptoCoin(id);
    isLoading(false);
    if (res) {
      setCryptoCoin(res);
    }
  };

  const getPaymentMethods = async (id) => {
    isLoading(true);
    const res = await PlayerAPI.getPaymentMethods(id);
    isLoading(false);
    if (res) {
      setPaymentMethods(res);
    }
  };

  const sendMail = async (payload) => {
    isLoading(true);
    await PlayerAPI.sendMail(payload);
    isLoading(false);
    return true;
  };

  return {
    getAllRooms,
    getSingleRoom,
    allRooms,
    singleRooms,
    createDonation,
    createRedeem,
    handleCallback,
    getCryptoCoin,
    cryptoCoin,
    getPaymentMethods,
    paymentMethod,
    sendMail,
  };
};

export default useHooks;
