/* eslint-disable prettier/prettier */
import styled from 'styled-components/macro';
import { COLORS } from 'theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;

  height: calc(80vh - 25px);
  justify-content: center;
  @media only screen and (max-width: 960px) and (orientation: landscape) {
    margin-top: 4rem;
    height: calc(80vh - 25px);
    overflow: auto;
  }
`;

export const InnerWrapper = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    padding: 0 1rem 1rem 1rem;
  }
`;

export const ItemField = styled.div`
  width: 100%;
  margin: 1rem 0;

  .input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${({ error }) => (error ? 'var(--error)' : 'grey')};
    height: 45px;
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }

  svg {
    width: 25px;
    height: 25px;
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 12px;
  }
`;

export const ItemFieldError = styled.div`
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  color: var(--error);

  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 9px;
  }
`;

export const Button = styled.div`
  background: ${COLORS.theme_gradient};
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Orbitron';
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    height: 35px;
    font-size: 14px;
  }
`;

export const ForgetContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .check-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    // checkbox start

    input[type='checkbox'] {
      appearance: none;
      -webkit-appearance: none;
      background: #0d0e10;
      border: 1px solid grey;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input[type='checkbox']:after {
      font-family: 'Font Awesome 5 Free';
      content: '\f00c';
      font-weight: 900;
      font-size: 14px;
      color: white;
      display: none;
    }
    input[type='checkbox']:hover {
      background: #1ebff3;
    }
    input[type='checkbox']:checked {
      background: #1ebff3;
    }
    input[type='checkbox']:checked:after {
      display: block;
    }

    // checkbox end
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 280px) and (max-width: 319px) {
    font-size: 10px;
  }
`;

export const SignupContainer = styled.div`
  display: flex;
  justify-content: center;

  .signup {
    margin-left: 5px;
    font-weight: 600;
    color: #17c5f7;
  }
  @media only screen and (min-width: 320px) and (max-width: 450px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 280px) and (max-width: 319px) {
    font-size: 10px;
  }
`;
