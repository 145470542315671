/* eslint-disable prettier/prettier */
import styled from 'styled-components/macro';
import { COLORS } from 'theme';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  height: calc(80vh - 25px);
  overflow: scroll;
  @media only screen and (min-width: 500px) and (max-width: 767px) {
    height: calc(82vh - 25px);
  }
  @media only screen and (min-width: 280px) and (max-width: 499px) {
    height: calc(86vh - 25px);
  }
`;

export const InnerWrapper = styled.div`
  width: 100%;
  padding: 2rem 4rem;
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    padding: 0 1rem 1rem 1rem;
  }
`;

export const ItemField = styled.div`
  width: 100%;
  margin: 1rem 0;

  .input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${({ error }) => (error ? 'var(--error)' : 'grey')};
    height: 45px;
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }

  svg {
    width: 25px;
    height: 25px;
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 12px;
  }
`;

export const ItemFieldError = styled.div`
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  color: var(--error);

  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 9px;
  }
`;
export const Button = styled.div`
  background: ${COLORS.theme_gradient};
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Orbitron';
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    height: 35px;
    font-size: 12px;
  }
`;

export const InstructionContainer = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 10px;
  }
`;

export const SignupContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .login {
    margin-left: 5px;
    font-weight: 600;
    color: #17c5f7;
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    margin: 20px 0;
    font-size: 12px;

    .login {
      font-weight: 500;
      font-size: 12px;
    }
  }
`;

export const TermsContainer = styled.div`
  margin-top: 1rem;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .highlight {
    font-weight: 600;
    font-size: 12px;
    color: #17c5f7;
    padding: 0 5px;
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 10px;
    .highlight {
      font-weight: 500;
      font-size: 10px;
    }
  }
`;

export const AgeContainer = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin: 1rem 0;
  input[type='radio'] {
    -webkit-appearance: radio;
    margin-right: 8px;
    accent-color: #1ebff3;

    width: 18px;
    height: 18px;
  }

  .radio_div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .radio_options {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 12px;
  }
`;
