/* eslint-disable prettier/prettier */
import React from 'react';
import { useState } from 'react';
import { emailValidator } from 'utils/validators';
import { Card, Notification } from '@components';
import { VisionSvg, EyeSvg } from '@svgs';
import useHooks from '../useHooks';
import {
  Wrapper,
  InnerWrapper,
  ItemField,
  Button,
  ForgetContainer,
  SignupContainer,
  ItemFieldError,
} from './styles';

const Login = ({ setPage, alert }) => {
  const [notificationAlert, setNotificationAlert] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [userInfoError, setUserInfoError] = useState({});
  const { login } = useHooks();

  React.useEffect(() => {
    setNotificationAlert(alert);
  }, [alert]);

  const validation = () => {
    const errors = {};
    let validation = true;
    if (emailValidator(userInfo.username)) {
      errors.username = emailValidator(userInfo.username);
      validation = false;
    }
    if (!validation) {
      setUserInfoError(errors);
    }

    return validation;
  };

  const logIn = async () => {
    if (validation()) {
      let formData = new FormData();
      for (const [key, value] of Object.entries(userInfo)) {
        formData.append(key, value);
      }
      const res = await login(formData);

      try {
        if (res.non_field_errors) {
          setNotificationAlert({
            title: 'Login Error!',
            text: res.non_field_errors[0],
          });
        } else if (res.message) {
          setNotificationAlert({
            title: 'Login Error!',
            text: res.message,
          });
        } else {
          setUserInfoError(res);
        }
      } catch (error) {
        setNotificationAlert({
          title: 'Login Error!',
          text: JSON.stringify(error),
        });
      }
    }
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    if (userInfoError[name]) {
      setUserInfoError({ ...userInfoError, [name]: '' });
    }
    setUserInfo({ ...userInfo, [name]: value });
  };

  const FIELDS = [
    {
      title: 'Email',
      name: 'username',
      placeholder: 'Email',
      onChange: onTextChange,
    },
    {
      title: 'Password',
      name: 'password',
      placeholder: 'Password',
      icon: showPassword ? EyeSvg : VisionSvg,
      type: showPassword ? 'text' : 'Password',
      onChange: onTextChange,
    },
  ];

  return (
    <Wrapper>
      <Card title={'LOGIN'}>
        <InnerWrapper>
          <Notification notification={notificationAlert} />
          {FIELDS.map((item, index) => {
            const Icon = item.icon;
            return (
              <ItemField key={index} error={userInfoError[item.name]}>
                <div className="input-row">
                  <input {...item} />
                  {Icon && <Icon onClick={() => setShowPassword(!showPassword)} />}
                </div>
                {userInfoError[item.name] && (
                  <ItemFieldError>{userInfoError[item.name]}</ItemFieldError>
                )}
              </ItemField>
            );
          })}

          <ForgetContainer>
            <div className="check-container">
              <input type="checkbox" />
              <div>Remember me</div>
            </div>
            <div onClick={() => setPage('reset-password')}>Forgot password?</div>
          </ForgetContainer>
          <Button onClick={logIn}>Login</Button>
          <SignupContainer>
            Don't have an account?
            <span className="signup" onClick={() => setPage('signup')}>
              Sign up
            </span>
          </SignupContainer>
        </InnerWrapper>
      </Card>
    </Wrapper>
  );
};

export default Login;
