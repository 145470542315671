/* eslint-disable no-useless-escape */
export const emailValidator = (email) => {
  console.log({ email });
  if (!email || email.length <= 0) {
    return 'Email field cannot be empty.';
  }

  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email)) {
    return 'Oops! We need a valid email address.';
  }

  return '';
};

export const textValidator = (text, filedName) => {
  const rex = /^[a-zA-Z ]+$/;
  if (!text || text.length <= 0) {
    return `${filedName} cannot be empty.`;
  }

  if (!rex.test(text)) {
    return `${filedName}  field contains only characters `;
  }
  return '';
};

export const requiredValidator = (text, fieldName) => {
  if (!text || text.length <= 0) {
    return `${fieldName} cannot be empty.`;
  }
  return '';
};

export const NumberValidator = (CNIC) => {
  if (!CNIC || CNIC.length <= 0) {
    return ' cannot be empty.';
  }
  if (isNaN(CNIC)) {
    return ' Should be in number format';
  }
  return '';
};

export const passwordValidator = (text) => {
  // const letters = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  const letters =
    /^(?=.*[0-9])(?=.*[!@#$%^&*(){}[\]:;"'`<>,.?/\\|_+=-])[a-zA-Z0-9!@#$%^&*(){}[\]:;"'<>,.?/\\|_+=-]{6,16}$/;
  if (!text || text.length <= 0) {
    return 'Password cannot be empty.';
  }
  if (!text || text.length < 8) {
    return 'Password cannot be less than 8 character.';
  }
  if (!letters.test(text)) {
    return 'Password must contain a special characters and a number ';
  }
  return '';
};

export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

export const UniqueByProperty = (arr, property) => {
  return [...new Set(arr.map((item) => item[property]))];
};

export const convertUniqueToDropdown = (arr, key) => {
  if ((arr, key)) {
    const arrayUniqueByKey = [...new Map(arr.map((item) => [item[key], item])).keys()];

    const arrList = [];
    arrayUniqueByKey?.forEach((item) => {
      arrList.push({ value: item, label: item });
    });

    return arrList;
  } else {
    return [];
  }
};

export const convertToDropdownFiltering = (arr, key, value, dropdownValue, dropdownLabel) => {
  if ((arr, key, value, dropdownValue, dropdownLabel)) {
    const list = arr.filter((obj) => obj[key] === value);

    const arrList = [];
    list.forEach((item) => {
      arrList.push({ value: item[dropdownValue], label: item[dropdownLabel] });
    });

    return arrList;
  } else {
    return [];
  }
};
