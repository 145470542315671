/* eslint-disable prettier/prettier */
import React from 'react';
import { Card } from '@components';
import { ArrowBackSvg } from '@svgs';
import { Wrapper, InnerWrapper, Title, EmailInfo, Button } from './styles';

const ResetToLogIn = ({ setPage, email }) => {
  return (
    <Wrapper>
      <Card>
        <InnerWrapper>
          <Title>
            <ArrowBackSvg onClick={() => setPage('reset-password')} className="back-arrow" />
            <div className="title">RESET PASSWORD</div>
          </Title>
          <EmailInfo>
            We have sent you instructions to change your password to {email || 'user@gmail.com'}
          </EmailInfo>
          <Button onClick={() => setPage('login')}>Go to Login</Button>
        </InnerWrapper>
      </Card>
    </Wrapper>
  );
};

export default ResetToLogIn;
