/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { COLORS } from 'theme';

export const Wrapper = styled.div`
  height: calc(80vh - 25px);
  display: flex;
  justify-content: center;
`;

export const InnerWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  .title {
    color: white;
    font-family: 'Pilot Command Laser Super-Ital';
    font-size: 34px;
    margin-left: 1.5rem;
  }
  .back-arrow {
    height: 30px;
    width: 30px;
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    .title {
      font-size: 16px;
      margin-left: 0.5rem;
    }
    .back-arrow {
      height: 20px;
      width: 20px;
    }
  }
`;
export const EmailInstructions = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0.5rem;
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 10px;
  }
`;
export const EmailInfo = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80%;
  font-size: 16px;
  font-weight: 400;
  margin-top: 2.5rem;
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 10px;
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;

export const ItemField = styled.div`
  width: 100%;
  padding: 2rem 4rem;

  .input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${({ error }) => (error ? 'var(--error)' : 'grey')};
    height: 45px;
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }

  svg {
    width: 25px;
    height: 25px;
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 12px;
  }
`;

export const ItemFieldError = styled.div`
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  color: var(--error);

  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 9px;
  }
`;

export const Button = styled.button`
  background: ${COLORS.theme_gradient};
  width: 75%;
  margin: 1.5rem 0;
  font-size: 16px;
  font-weight: 500;
  padding: 0.5rem;
  font-family: 'Orbitron';
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 12px;
  }
`;
