/* eslint-disable prettier/prettier */
import React from 'react';
import { useState } from 'react';
import { emailValidator } from 'utils/validators';
import { Card } from '@components';
import { ArrowBackSvg } from '@svgs';
import useHooks from '../useHooks';
import {
  Wrapper,
  InnerWrapper,
  Title,
  EmailInstructions,
  Button,
  ItemField,
  ItemFieldError,
} from './styles';

const ResetPassword = ({ setPage }) => {
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const { forgotpassword } = useHooks();
  const passwordUpdate = async () => {
    if (emailValidator(email)) {
      setEmailError(emailValidator(email));
    } else {
      await forgotpassword({ email: email }, setPage);
    }
  };
  return (
    <Wrapper>
      <Card>
        <InnerWrapper>
          <Title>
            <ArrowBackSvg onClick={() => setPage('login')} className="back-arrow" />
            <div className="title">RESET PASSWORD</div>
          </Title>
          <EmailInstructions>
            Enter the email associated with your account and we will send you a link to reset your
            password.
          </EmailInstructions>

          <ItemField error={emailError}>
            <div className="input-row">
              <input
                placeholder="Enter your email"
                className="emailnput"
                onChange={(e) => {
                  if (emailError) setEmailError('');
                  setEmail(e.target.value);
                }}
              />
            </div>
            {emailError && <ItemFieldError>{emailError}</ItemFieldError>}
          </ItemField>
          <Button onClick={() => passwordUpdate()}>Reset</Button>
        </InnerWrapper>
      </Card>
    </Wrapper>
  );
};

export default ResetPassword;
