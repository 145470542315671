import swal from 'sweetalert';

const successSwal = (text, title = 'Success') => {
  swal({
    title: title,
    text: text,
    icon: 'success',
    button: 'Close',
    className: 'swal-custom',
    timer: 5000,
  });
};

const errorSwal = (text, title = 'Error') => {
  swal({
    title: title,
    text: text,
    icon: 'error',
    button: 'Close',
    timer: 5000,
  });
};

const swalAlert = (text, title, type = 'error') => {
  if (type === 'error') {
    errorSwal(text, title);
  } else {
    successSwal(text, title);
  }
};

export default swalAlert;
