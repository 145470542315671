/* eslint-disable prettier/prettier */
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoSvg } from '@svgs';
import { LineWrapper, ImageWrapper, RightLineWrapper, MainWrapper } from './style.js';

const Splash = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/auth');
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MainWrapper>
      <LineWrapper>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </LineWrapper>
      <ImageWrapper>
        <LogoSvg className="img-style" />
      </ImageWrapper>
      <RightLineWrapper>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </RightLineWrapper>
    </MainWrapper>
  );
};

export default Splash;
