/* eslint-disable prettier/prettier */
import React from 'react';
import { OuterWrapper, InnerWrapper, OuterShadow, InnerShadow, Title } from './styles';

const Card = ({ children, title }) => {
  return (
    <OuterShadow>
      <InnerShadow>
        <OuterWrapper>
          <InnerWrapper>
            {title && <Title>{title}</Title>}
            {children}
          </InnerWrapper>
        </OuterWrapper>
      </InnerShadow>
    </OuterShadow>
  );
};

export default Card;
