/* eslint-disable prettier/prettier */
import Feedback from './feedback';
import HelpAndSupport from './help-and-support';
import Home from './home';
import Room from './home/room';
import PrivacyNotice from './privacy-policy';
import Profile from './profile';
import ChangePassword from './profile/change-password';
import TermsAndConditions from './terms-and-conditions';
import Wallet from './wallet';

const pages = {
  home: Home,
  room: Room,
  feedback: Feedback,
  'terms-and-conditions': TermsAndConditions,
  'privacy-notice': PrivacyNotice,
  wallet: Wallet,
  profile: Profile,
  'change-password': ChangePassword,
  'help-and-support': HelpAndSupport,
};

export default pages;
