/* eslint-disable prettier/prettier */
import { getRequest, postRequest } from './methods';

const login = async (payload) => {
  return await postRequest(`login/`, payload);
};

const signup = async (payload) => {
  return await postRequest(`signup/`, payload);
};
const privacynotice = async (payload, module) => {
  return await getRequest(`modules/privacy-policy/`, payload, module);
};
const conditionsandterms = async (payload, module) => {
  return await getRequest(`modules/terms-and-conditions/`, payload, module);
};
const forgotpassword = async (payload) => {
  return await postRequest(`forgotpassword/`, payload);
};
export const registrationAPI = {
  login,
  signup,
  privacynotice,
  conditionsandterms,
  forgotpassword,
};
