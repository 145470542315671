/* eslint-disable prettier/prettier */
import { Routes, Route } from 'react-router-dom';
import { Loader } from '@components';
import Main from './Auth';
import Home from './screens';
import RocketFuelCallback from './screens/home/rocketFuelCallback';
import useLoader from './sharedHooks/useLoader';
import Splash from './splash-screen';

const AppRoutes = () => {
  const { loading } = useLoader();

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/auth" element={<Main />} />
        <Route path="/home" element={<Home />} />
        <Route path="/rocketFuel/:callback" element={<RocketFuelCallback />} />

        <Route path="*" element={<Splash />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
