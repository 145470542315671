/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { COLORS } from 'theme';

export const Wrapper = styled.div`
  height: var(--height);
  background: ${COLORS.theme_color};
  display: flex;
  flex-direction: column;
`;

export const Navbar = styled.div`
  background: ${COLORS.theme_dark};
  width: ${({ open }) => (open ? '70%' : '0%')};
  height: 100%;
  -webkit-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
`;

export const Copyright = styled.div`
  color: #ffffff80;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
