/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sortBy } from 'lodash';
import useLoader from '@containers/sharedHooks/useLoader';
import { registrationAPI } from '@libs/api/auth.api';
import { UserAPI } from '@libs/api/user.api';

const useHooks = () => {
  const { isLoading } = useLoader();
  const navigate = useNavigate();
  const { userInfo, userAllData } = useSelector((state) => state.SharedStore);
  const [privacyNotice, setPrivacyNotice] = useState();
  const [termsandconditions, setTermsandConditions] = useState();
  const [allArea, setAllArea] = useState();
  const [allCities, setAllCities] = useState();
  const dispatch = useDispatch();
  const updateUserDetails = (item) => {
    localStorage.setItem('token', item.token);
    dispatch({
      type: 'USER',
      payload: item.user,
    });
    dispatch({
      type: 'PROFILE',
      payload: item,
    });
  };

  const login = async (payload) => {
    isLoading(true);
    const res = await registrationAPI.login(payload);
    isLoading(false);
    if (res.success) {
      const data = res.success;
      updateUserDetails(data);
      navigate('/home');
    } else {
      return res;
    }
  };
  const signup = async (payload) => {
    isLoading(true);
    const res = await registrationAPI.signup(payload);
    isLoading(false);
    return res;
  };
  const privacynotice = async (payload) => {
    isLoading(true);
    const module = true;
    const res = await registrationAPI.privacynotice(payload, module);
    isLoading(false);
    if (res) {
      setPrivacyNotice(res[0].body);
    }
  };
  const conditionsandterms = async (payload) => {
    isLoading(true);
    const module = true;
    const res = await registrationAPI.conditionsandterms(payload, module);
    isLoading(false);
    if (res) {
      setTermsandConditions(res[0].body);
    }
  };
  const forgotpassword = async (payload, setPage) => {
    isLoading(true);
    const module = true;
    await registrationAPI.forgotpassword(payload, module);
    isLoading(false);
    setPage({ id: 'reset-to-login', ...payload });
  };
  const getAllArea = async () => {
    isLoading(true);
    const res = await UserAPI.getArea();
    isLoading(false);

    if (res) {
      let sorted = sortBy(res, (o) => o.order);
      setAllArea(sorted);
    }
  };

  const getCity = async (data) => {
    isLoading(true);
    const res = await UserAPI.getCity(data);
    setAllCities(res.cities);
    localStorage.clear();
    isLoading(false);
  };

  return {
    login,
    signup,
    userInfo,
    userAllData,
    privacynotice,
    privacyNotice,
    termsandconditions,
    conditionsandterms,
    forgotpassword,
    getAllArea,
    allArea,
    getCity,
    allCities,
  };
};

export default useHooks;
