/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { COLORS } from 'theme';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 0;
  justify-content: center;
  font-size: 34px;
  font-weight: 400;
  font-family: 'Pilot Command Laser Super-Ital';
  color: white;
  height: 12vh;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 88vh;
`;
export const CardContainer = styled.div`
  width: max(40%, 580px);
  margin: 1rem;
`;
export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
`;
export const CardBody = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  textarea {
    border: 1px solid rgba(16, 210, 250, 1);
    background: rgba(11, 13, 17, 1);
    height: 220px;
    overflow: auto;
    color: white;
    font-size: 16px;
    font-weight: 400;
    padding: 1rem;
    font-family: 'Montserrat';
  }
  button {
    font-family: 'Orbitron';
    font-size: 16px;
    font-weight: 500;
    margin: 1rem 0;
    background: ${COLORS.theme_gradient};
    padding: 0.5rem;
    color: white;
    opacity: ${({ disable }) => (disable ? 0.5 : 1)};
  }
`;
