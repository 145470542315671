/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { COLORS } from 'theme';

export const MainWrapper = styled.div`
  height: 100vh;
  width: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LineWrapper = styled.div`
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  .line1 {
    width: 45%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  .line2 {
    margin-top: 18px;
    width: 35%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  .line3 {
    margin-top: 18px;
    width: 30%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
`;
export const RightLineWrapper = styled.div`
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  direction: rtl;
  .line1 {
    width: 45%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  .line2 {
    margin-top: 18px;
    width: 35%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  .line3 {
    margin-top: 18px;
    width: 30%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
