/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { COLORS } from 'theme';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 0;
  justify-content: center;
  font-size: 34px;
  font-weight: 400;
  font-family: 'Pilot Command Laser Super-Ital';
  color: white;
  height: 12vh;
  margin-right: 3rem;
  .back-arrow {
    height: 30px;
    width: 30px;
    margin-right: 2rem;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 88vh;
`;
export const CardContainer = styled.div`
  width: max(40%, 340px);
  margin: 1rem;
  .card-img {
    width: 100%;
    height: auto;
  }
  .room {
    padding: 1rem;
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
`;
export const CardBody = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'Montserrat';
`;
export const Button = styled.button`
  font-family: 'Orbitron';
  font-size: 16px;
  font-weight: 500;
  margin: 1rem 0;
  background: ${COLORS.theme_gradient};
  padding: 0.5rem;
`;

export const ItemField = styled.div`
  width: 100%;
  margin: 1rem 0;

  .input-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid grey;
    height: 45px;
  }

  input {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }

  svg {
    width: 25px;
    height: 25px;
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 12px;
  }
`;

export const ItemFieldError = styled.div`
  width: 100%;
  font-size: 14px;
  color: var(--error);

  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 9px;
  }
`;
export const InstructionContainer = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-weight: 400;
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 10px;
  }
`;
