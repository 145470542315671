/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Sidebar } from '@components';
import { BarsSvg, CloseSvg } from '@svgs';
import { LayoutWrapper } from './style';

const Layout = ({ children, setPage, page }) => {
  const [navbarShow, setNavbarShow] = useState(false);
  return (
    <LayoutWrapper navbarShow={navbarShow}>
      <div className=" bottom_navbar">
        <CloseSvg className="close" onClick={() => setNavbarShow(false)} />

        <Sidebar setPage={setPage} page={page} />
      </div>
      <div className="main_background_div">
        <BarsSvg className="hamburger" onClick={() => setNavbarShow(true)} />
        {children}
      </div>
    </LayoutWrapper>
  );
};

export default Layout;
