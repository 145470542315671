import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useHooks from '../useHooks';

const FundraiserWidget = () => {
  const { callback } = useParams();
  const { handleCallback } = useHooks();

  useEffect(() => {
    if (callback) {
      handleCallback(callback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
};

export default FundraiserWidget;
