import React from 'react';
import { Table } from 'antd';
import './style.css';

const WalletTable = ({ columns, tableData }) => {
  const onChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };
  let locale = {
    emptyText: 'Loading....',
  };
  return (
    <div style={{ overflow: 'auto' }}>
      <div style={{ width: '100%' }}>
        <Table
          locale={locale}
          showSorterTooltip={false}
          className="customTable"
          columns={columns}
          dataSource={tableData}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default WalletTable;
