/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { sortBy } from 'lodash';
import useLoader from '@containers/sharedHooks/useLoader';
import { PlayerAPI } from '@libs/api';

const useHooks = () => {
  const { isLoading } = useLoader();
  const [allHelpUrls, setHelpUrls] = useState();

  const getHelpUrls = async () => {
    isLoading(true);
    const res = await PlayerAPI.getHelpUrls();
    isLoading(false);

    if (res) {
      let sorted = sortBy(res, (o) => o.order);
      setHelpUrls(sorted);
    }
  };

  return {
    allHelpUrls,
    getHelpUrls,
  };
};

export default useHooks;
