/* eslint-disable prettier/prettier */
import { getRequest, postRequest, putRequest } from './methods';

const getAllRooms = async (payload) => {
  return await getRequest(`rooms/`, payload);
};
const getSingleRoom = async (id) => {
  return await getRequest(`rooms/${id}/`);
};

const createDonation = async (payload) => {
  return await postRequest(`transactions/`, payload);
};

const createRedeem = async (payload) => {
  return await postRequest(`redeem/`, payload);
};

const getTransactions = async (id) => {
  return await getRequest(`transactions/`);
};

const updateTransation = async (BE_TransactionId) => {
  return await putRequest(`transactions/${BE_TransactionId}/update_transaction/`, {
    coin_amount: 0,
    coin_type: 'btc',
  });
};

const getCryptoCoin = async (room_id) => {
  return await getRequest(`coin/?room_id=${room_id}`);
};

const sendMail = async (payload) => {
  const room_id = payload.room_id;
  delete payload.room_id;

  return await postRequest(`support/?room_id=${room_id}`, payload);
};

const getPaymentMethods = async (room_id) => {
  return await getRequest(`payment-method/?room_id=${room_id}`);
};

const getHelpUrls = async () => {
  return await getRequest('help-and-support/');
};

export const PlayerAPI = {
  getAllRooms,
  getSingleRoom,
  createDonation,
  createRedeem,
  getTransactions,
  updateTransation,
  getCryptoCoin,
  sendMail,
  getHelpUrls,
  getPaymentMethods,
};
