import React from 'react';
import { Layout, Spinner } from '@components';
import Card from '../card';
import { Header, CardWrapper, MainWrapper, Wrapper, CardContainer } from './styles';
import useHooks from './useHooks';

const HelpAndSupport = ({ setPage, page }) => {
  const { getHelpUrls, allHelpUrls } = useHooks();
  React.useEffect(() => {
    getHelpUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!allHelpUrls) return <Spinner />;
  return (
    <Layout setPage={setPage} page={page}>
      <MainWrapper>
        <Header>Help and Support</Header>
        <Wrapper>
          <CardWrapper>
            <Card className="help_wrapper">
              <div className="help-content-section">
                {allHelpUrls.length <= 0 ? (
                  <div className="help-no-data">No Data</div>
                ) : (
                  <>
                    {allHelpUrls.map((element, index) => {
                      return (
                        <div className="help-content-wrapper" key={element.id}>
                          <div className="help">
                            <img
                              src={element.support_image}
                              alt="url-image"
                              className="help-url-img"
                            />
                            <p className="help-url">
                              <a href={element.url} target="_blank">
                                {element.url}
                              </a>
                            </p>
                            <div className="link-divider"></div>
                            <div className="support-link">Support Link</div>
                          </div>

                          <span className="help-desc">{element.description}</span>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </Card>
          </CardWrapper>
        </Wrapper>
      </MainWrapper>
    </Layout>
  );
};

export default HelpAndSupport;
