/* eslint-disable prettier/prettier */
import React from 'react';
import { useState } from 'react';
import { passwordValidator } from 'utils/validators';
import { Layout } from '@components';
import Card from '@containers/screens/card';
import { ArrowBackSvg } from '@svgs';
import { VisionSvg, EyeSvg } from '@svgs';
import SwalAlert from '@utils/swal';
import userHook from '../useHooks';
import {
  Header,
  CardWrapper,
  MainWrapper,
  Wrapper,
  CardContainer,
  CardBody,
  ItemField,
  Button,
  InstructionContainer,
  ItemFieldError,
} from './style';

const ChangePassword = ({ setPage, page }) => {
  const [userInfo, setUserInfo] = useState({});
  const [userInfoError, setUserInfoError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);

  const { updatePassword } = userHook();
  const FIELDS = [
    {
      title: 'Old Password',
      name: 'current_password',
      placeholder: 'Old Password',
      icon: showPassword ? EyeSvg : VisionSvg,
      type: showPassword ? 'text' : 'Password',
      iconClick: () => setShowPassword(!showPassword),
    },

    {
      title: 'New Password',
      name: 'new_password',
      placeholder: 'New Password',
      type: showNewPasswordConfirm ? 'text' : 'Password',
      icon: showNewPasswordConfirm ? EyeSvg : VisionSvg,
      iconClick: () => setShowNewPasswordConfirm(!showNewPasswordConfirm),
    },
    {
      title: 'Confirm Password',
      name: 'confirm_new_password',
      placeholder: 'Confirm Password',
      icon: showNewPassword ? EyeSvg : VisionSvg,
      type: showNewPassword ? 'text' : 'Password',
      iconClick: () => setShowNewPassword(!showNewPassword),
    },
  ];

  const update = async () => {
    if (validation()) {
      const res = await updatePassword(userInfo);
      console.log(res);
      if (res?.success?.success) {
        setPage('home');
        SwalAlert(res?.success?.message, 'Success', 'success');
      } else {
        SwalAlert(res?.message);
      }
    }
  };

  const validation = () => {
    const errors = {};
    let validation = true;

    if (passwordValidator(userInfo.current_password)) {
      errors.current_password = passwordValidator(userInfo.current_password);
      validation = false;
    }
    if (passwordValidator(userInfo.new_password)) {
      errors.new_password = passwordValidator(userInfo.new_password);
      validation = false;
    }
    if (userInfo.confirm_new_password !== userInfo.new_password) {
      errors.confirm_new_password = 'New password does not match with confirm new password.';
      validation = false;
    }
    if (!validation) {
      setUserInfoError(errors);
    }

    return validation;
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    if (userInfoError[name]) {
      setUserInfoError({ ...userInfoError, [name]: '' });
    }
    setUserInfo({ ...userInfo, [name]: value });
  };

  return (
    <Layout setPage={setPage} page={page}>
      <MainWrapper>
        <Header>
          <ArrowBackSvg className="back-arrow" onClick={() => setPage({ id: 'profile' })} />
          <span>CHANDE PASSWORD</span>
        </Header>
        <Wrapper>
          <CardWrapper>
            <CardContainer>
              <Card>
                <CardBody>
                  {FIELDS.map((item, index) => {
                    const Icon = item.icon;

                    const { iconClick } = item;
                    return (
                      <ItemField key={index}>
                        <div className="input-row">
                          <input {...item} onChange={onTextChange} />
                          {Icon && <Icon onClick={iconClick} />}
                        </div>
                        {userInfoError[item.name] && (
                          <ItemFieldError>{userInfoError[item.name]}</ItemFieldError>
                        )}
                      </ItemField>
                    );
                  })}

                  <InstructionContainer>
                    Password must be at least 8 alphanumeric, with at least one special character
                    and one number.
                  </InstructionContainer>
                  <Button onClick={update}>Save</Button>
                </CardBody>
              </Card>
            </CardContainer>
          </CardWrapper>
        </Wrapper>
      </MainWrapper>
    </Layout>
  );
};

export default ChangePassword;
