/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { COLORS } from 'theme';

export const MainWrapper = styled.div`
  height: 20vh;
  width: 100%;
  @media only screen and (min-width: 500px) and (max-width: 767px) {
    height: 15vh;
  }
  @media only screen and (min-width: 280px) and (max-width: 499px) {
    height: 10vh;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    grid-template-columns: 25% 50% 25%;
  }
  @media only screen and (min-width: 280px) and (max-width: 319px) {
    grid-template-columns: 15% 70% 15%;
  }
`;

export const LineWrapper = styled.div`
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  .line1 {
    width: 95%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  .line2 {
    margin-top: 18px;
    width: 80%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  .line3 {
    margin-top: 18px;
    width: 72%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  @media only screen and (min-width: 501px) and (max-width: 767px) {
    padding: 2rem 0;
    .line2 {
      margin-top: 14px;
    }
    .line3 {
      margin-top: 14px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 500px) {
    padding: 1.5rem 0;
    .line2 {
      margin-top: 8px;
    }
    .line3 {
      margin-top: 8px;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 319px) {
    padding: 1.5rem 0;
    .line1 {
      height: 1px;
    }
    .line2 {
      margin-top: 8px;
      height: 1px;
    }
    .line3 {
      margin-top: 8px;
      height: 1px;
    }
  }
`;
export const RightLineWrapper = styled.div`
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  direction: rtl;
  .line1 {
    width: 95%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  .line2 {
    margin-top: 18px;
    width: 80%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  .line3 {
    margin-top: 18px;
    width: 72%;
    background: ${COLORS.theme_gradient};
    height: 2px;
  }
  @media only screen and (min-width: 501px) and (max-width: 767px) {
    padding: 2rem 0;
    .line2 {
      margin-top: 14px;
    }
    .line3 {
      margin-top: 14px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 500px) {
    padding: 1.5rem 0;
    .line2 {
      margin-top: 8px;
    }
    .line3 {
      margin-top: 8px;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 319px) {
    padding: 1.5rem 0;
    .line1 {
      height: 1px;
    }
    .line2 {
      margin-top: 8px;
      height: 1px;
    }
    .line3 {
      margin-top: 8px;
      height: 1px;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: 501px) and (max-width: 767px) {
    .img-style {
      height: 90px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 500px) {
    align-items: start;
    .img-style {
      height: 70px;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 319px) {
    .img-style {
      height: 40px;
    }
  }
`;
