/* eslint-disable prettier/prettier */
import React from 'react';
import {
  connect,
  useSelector, //  useDispatch
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import pages from './pages';
import { Wrapper, Copyright } from './styles';

const Auth = (props) => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.SharedStore);
  const [page, setPage] = React.useState(props.page || 'login');
  const Component = pages[page.id || page];

  React.useEffect(() => {
    if (userInfo.id) {
      navigate('/home');
    }
  }, [navigate, userInfo]);

  return (
    <Wrapper>
      <Header />
      <div style={{ flex: 1 }}>
        <Component setPage={setPage} {...page} />
      </div>
      <Copyright>Copyright 2022 Slyod Designs. All Rights Reserved</Copyright>
    </Wrapper>
  );
};

export default connect()(Auth);
