/* eslint-disable prettier/prettier */
import React from 'react';
import { useSelector } from 'react-redux';
import { FeedbackSvg, HomeSvg, SlySvg, TermsSvg, WalletSvg } from '@svgs';
import {
  SidebarWrapper,
  ImgContainer,
  ListContainer,
  ElementWrapper,
  ProfileContainer,
} from './style';

const Sidebar = ({ setPage, page }) => {
  const { userInfo } = useSelector((state) => state.SharedStore);
  const menuData = [
    {
      id: 1,
      title: 'Home',
      icon: HomeSvg,
      path: 'home',
    },
    {
      id: 2,
      title: 'Wallet',
      icon: WalletSvg,
      path: 'wallet',
    },
    {
      id: 3,
      title: 'Feedback',
      icon: FeedbackSvg,
      path: 'feedback',
    },
    {
      id: 4,
      title: 'Terms and Conditions',
      icon: TermsSvg,
      path: 'terms-and-conditions',
    },
    {
      id: 5,
      title: 'Privacy Notice',
      icon: TermsSvg,
      path: 'privacy-notice',
    },
    {
      id: 6,
      title: 'Help and Support',
      icon: TermsSvg,
      path: 'help-and-support',
    },
  ];
  return (
    <SidebarWrapper>
      <ImgContainer>
        <SlySvg />
      </ImgContainer>
      <ProfileContainer
        active={page === 'profile'}
        onClick={() => {
          setPage({ id: 'profile' });
        }}
      >
        <div className="imgContainer"></div>
        <div className="profile">
          <span className="name">{`${userInfo.first_name} ${userInfo.last_name}` || 'Name'} </span>
          <span className="myprofile">My Profile</span>
        </div>
      </ProfileContainer>
      <ListContainer>
        {menuData.map((element, index) => {
          const Icon = element.icon;
          return (
            <li key={index}>
              <ElementWrapper
                active={page === element.path}
                onClick={() => {
                  setPage({ id: element.path });
                }}
              >
                <Icon style={{ width: 25, height: 25, marginRight: '2rem' }} />
                {element.title}
              </ElementWrapper>
            </li>
          );
        })}
      </ListContainer>
    </SidebarWrapper>
  );
};

export default Sidebar;
