/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Layout } from '@components';
import SwalAlert from '@utils/swal';
import Card from '../card';
import { Header, CardWrapper, MainWrapper, Wrapper, CardContainer, CardBody } from './styles';
import useHooks from './useHooks';

const Feedback = ({ setPage, page }) => {
  const [userInfo, setUserInfo] = useState({});

  const { sendFeedback } = useHooks();

  const submit = async () => {
    const res = await sendFeedback(userInfo);
    if (res?.success?.success) {
      setPage('home');
      SwalAlert(res?.success?.message, 'Success', 'success');
    } else {
      SwalAlert(res?.success?.message);
    }
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  return (
    <Layout setPage={setPage} page={page}>
      <MainWrapper>
        <Header>FEEDBACK</Header>
        <Wrapper>
          <CardWrapper>
            <CardContainer>
              <Card>
                <CardBody disable={!userInfo?.feedback}>
                  <textarea name="feedback" placeholder="Your Comments" onChange={onTextChange} />
                  <button onClick={() => (userInfo?.feedback ? submit() : null)}>Submit</button>
                </CardBody>
              </Card>
            </CardContainer>
          </CardWrapper>
        </Wrapper>
      </MainWrapper>
    </Layout>
  );
};

export default Feedback;
