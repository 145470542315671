/* eslint-disable prettier/prettier */
import { useState } from 'react';
import useLoader from '@containers/sharedHooks/useLoader';
import { PlayerAPI } from '@libs/api';

const useHooks = () => {
  const { isLoading } = useLoader();
  const [allTransactions, setAllTransactions] = useState([]);

  const getTransactions = async (id) => {
    isLoading(true);
    const res = await PlayerAPI.getTransactions(id);
    isLoading(false);
    if (res) {
      setAllTransactions(res);
    }
  };

  return {
    getTransactions,
    allTransactions,
  };
};

export default useHooks;
