/* eslint-disable prettier/prettier */
import React from 'react';
import { useEffect } from 'react';
import { ArrowBackSvg } from '@svgs';
import useHooks from '../useHooks';
import { Wrapper, TitleContainer, Paragraph } from './style';

const PrivacyNotice = ({ setPage }) => {
  const { privacynotice, privacyNotice } = useHooks();

  useEffect(() => {
    privacynotice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrapper>
      <TitleContainer>
        <ArrowBackSvg className="back-arrow" onClick={() => setPage('signup')} />
        <span className="title"> PRIVACY NOTICE </span>
      </TitleContainer>
      <Paragraph dangerouslySetInnerHTML={{ __html: privacyNotice }} />
    </Wrapper>
  );
};

export default PrivacyNotice;
