/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  font-family: 'Montserrat';
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 0;
  justify-content: center;
  font-size: 34px;
  font-weight: 400;
  font-family: 'Pilot Command Laser Super-Ital';
  color: white;
  height: 12vh;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 88vh;
`;
export const CardContainer = styled.div`
  width: max(30%, 260px);
  margin: 1rem;
  .card-img {
    width: 100%;
    height: auto;
  }
  .room {
    padding: 1rem;
    color: rgba(23, 197, 247, 1);
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem 1rem;
`;
