/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  font-size: 34px;
  font-weight: 400;
  font-family: 'Pilot Command Laser Super-Ital';
  color: white;
  height: 12vh;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 88vh;
`;
export const CardContainer = styled.div`
  width: 100%;
  margin: 1rem;
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem 1rem;
`;
export const InnerCard = styled.div`
  padding: 2rem;
`;
export const CardHeader = styled.div`
  padding-bottom: 1rem;
  color: white;
  border-bottom: 1px solid rgba(16, 210, 250, 0.22);
  font-size: 32px;
  font-weight: 700;
  font-family: 'Montserrat';
`;
export const CardBody = styled.div`
  border-bottom: 1px solid rgba(16, 210, 250, 0.22);
  padding: 1rem 0;
  color: rgba(21, 174, 219, 1);
  font-weight: 600;
  font-size: 20px;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
`;
export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .small-text {
    font-size: 12px;
    font-weight: 400;
    color: #b1b1b1;
  }
`;
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(16, 210, 250, 0.22);
  padding: 1rem 0;
  color: rgba(21, 174, 219, 1);
  font-weight: 600;
  font-size: 20px;
  font-family: 'Montserrat';
  // align-items: center;
  color: white;
  // justify-content: space-around;
`;

export const TableWrapper = styled.table``;

export const TextItems = styled.td`
  color: white;
`;
