/* eslint-disable prettier/prettier */
import Login from './login';
import PrivacyNotice from './privacy-policy';
import ResetPassword from './reset-password';
import ResetToLogIn from './reset-password/reset-to-login';
import SignUp from './sign-up';
import TermsAndConditions from './terms-and-conditions';

const pages = {
  login: Login,
  signup: SignUp,
  'reset-password': ResetPassword,
  'reset-to-login': ResetToLogIn,
  'terms-and-conditions': TermsAndConditions,
  'privacy-policy': PrivacyNotice,
};

export default pages;
