import { createGlobalStyle } from 'styled-components';

const w1 = Math.ceil((1 / window.devicePixelRatio) * 10) / 10;

export default createGlobalStyle`
  html {
    overflow: hidden;
  }
  body {
    position: relative;
    width: var(--width);
    height: var(--height);
    margin: 0;
    font-family: 'SF Pro';
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow: hidden;

    --w1: ${w1}px;
    --_w1: ${-w1}px;

    --theme-solid: #003627;
    --theme-light: #003627;
    --overlay: rgba(255, 255, 255, 0.25);
    
    --bg-color: #fff;
    --bg-color1: #f5f5f5;
    --text-color: #000;
    --text-color1: hsl(240deg 1% 55%);
    --line-color1: rgba(0, 0, 0, 0.1);
    --line-color2: hsl(240deg 1% 66%);

    &.dark {
      --bg-color: #000;
      --bg-color1: hsl(240deg 6% 12%);
      --text-color: #fff;
      --text-color1: hsl(240deg 4% 57%);
      --line-color1: hsl(240deg 4% 21%);
      --line-color2: hsl(240deg 4% 39%);
    }

    --error: #EF0454;
    --red: #ff3a30;
    --red1: #F4513A;
    --red2: #FDD5D0;
    --green: #34c659;
    --blue: #20a8ff;
    --easeOutQuart: cubic-bezier(0.25, 1, 0.5, 1);
    --easeOutCirc: cubic-bezier(0, 0.55, 0.45, 1);

    color: var(--text-color);
    background-color: var(--bg-color);
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    user-select: none;
    outline: none;
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
  }

  ::-webkit-scrollbar {
    width: 0;
  }

  input, button {
    border: none;
    background-color: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
  }

  input {
    -webkit-appearance: none;
    user-select: initial;

    &::placeholder {
      color: inherit;
      opacity: 0.4;
    }
  }

  #root {
    width: 100%;
    height: 100%;
  }

  .numeric {
    font-variant-numeric: tabular-nums;
  }

  .message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.38;
  }

  body > iframe {
    display: none;
  }
  :root {
    --primary2: #f3833f;
    --primary: #4F45A8;
    --button: #878FAD;
    --buttonbackground:#DCD8FF;
    --tablehead:#EFEDFF;
    --greybtnborder:#686868;
    --greybtnText:#383838;
    --tableText:#232428;
    --gradientblack: rgba(18, 18, 18, 0.6);
    --bordercolor: #DDDDDD;
    --lightText:#5F5F5F;
    --light:rgb(240 245 255);
    /* --light: rgb(163, 176, 245); */
    /* --lightText: #AAAAAA; */
    --gray: #6d6d6d;
    --darkGray: #5e5c5c;
    --gray2: #999;
    --black: #000;
    --white: #fff;
    --active_log_circle:#e6b6a8;
    --border:#edeef0;
    --bgLight:#f6f7fb;
    --newBg:#7bb3fe;
    --logo:#009adf;
  }
  
  .font-gilroy-black {
    font-family: "Gilroy-Black";
   }
   
   .font-sans-serif{
    font-family: 'DM Sans', sans-serif;
   }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', Gilroy-Black monospace;
  }
`;
