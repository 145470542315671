/* eslint-disable prettier/prettier */
const initialState = {
  loading: false,
  userInfo: {},
  userAllData: [],
  ordersList: [],
};

const SharedStore = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };

    case 'USER':
      return {
        ...state,
        userInfo: action.payload,
      };

    case 'PROFILE':
      return {
        ...state,
        userAllData: action.payload,
      };

    case 'LOGOUT':
      return {
        ...state,
        userInfo: {},
      };

    default:
      return state;
  }
};

export default SharedStore;
