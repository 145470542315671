/* eslint-disable prettier/prettier */
import useLoader from '@containers/sharedHooks/useLoader';
import { UserAPI } from '@libs/api/user.api';

const useHooks = () => {
  const { isLoading } = useLoader();

  const sendFeedback = async (payload) => {
    isLoading(true);
    const res = await UserAPI.feedback(payload);
    isLoading(false);

    return res;
  };

  return {
    sendFeedback,
  };
};

export default useHooks;
