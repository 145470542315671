/* eslint-disable prettier/prettier */
import React from 'react';
import { Layout, Spinner } from '@components';
import Card from '../card';
import { Header, CardWrapper, MainWrapper, Wrapper, CardContainer } from './style';
import useHooks from './useHooks';

const Home = ({ setPage, page }) => {
  const { getAllRooms, allRooms } = useHooks();
  React.useEffect(() => {
    getAllRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!allRooms) return <Spinner />;
  return (
    <Layout setPage={setPage} page={page}>
      <MainWrapper>
        <Header>Home</Header>
        <Wrapper>
          <CardWrapper>
            {allRooms.map((element, index) => {
              return (
                <CardContainer key={index}>
                  <Card>
                    <img src={element.image} alt="" className="card-img" />
                    <div
                      className="room"
                      onClick={() => {
                        setPage({ id: 'room', data: { element: element, setPage: setPage } });
                      }}
                    >
                      {element.title}
                    </div>
                  </Card>
                </CardContainer>
              );
            })}
          </CardWrapper>
        </Wrapper>
      </MainWrapper>
    </Layout>
  );
};

export default Home;
