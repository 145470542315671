/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Wrapper = styled.div`
  color: white;
  width: 100%;
  display: flex;
  font-family: 'Montserrat';
  flex-direction: column;
  height: calc(80vh - 25px);
  overflow: scroll;
`;
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  .title {
    color: white;
    font-family: 'Pilot Command Laser Super-Ital';
    font-size: 34px;
    margin-left: 1.5rem;
  }
  .back-arrow {
    height: 30px;
    width: 30px;
  }
  @media only screen and (min-width: 280px) and (max-width: 450px) {
    .title {
      font-size: 16px;
      margin-left: 0.5rem;
    }
    .back-arrow {
      height: 20px;
      width: 20px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  font-size: 34px;
  font-weight: 400;
  font-family: 'Pilot Command Laser Super-Ital';
  color: white;
  height: 12vh;
`;

export const Paragraph = styled.ol`
  padding-left: 0;
  font-size: 12px;
  text-align: justify;
  white-space: pre-wrap;
  margin: 1.5rem;
`;
