/* eslint-disable prettier/prettier */
import React from 'react';
import { OuterWrapper, InnerWrapper, OuterShadow, InnerShadow } from './styles';

const Card = ({ children, className }) => {
  return (
    <OuterShadow>
      <InnerShadow>
        <OuterWrapper>
          <InnerWrapper className={className}>{children}</InnerWrapper>
        </OuterWrapper>
      </InnerShadow>
    </OuterShadow>
  );
};

export default Card;
