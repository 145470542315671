/* eslint-disable prettier/prettier */
import SwalAlert from '@utils/swal';

export const header = async (formdata) => {
  const token = await localStorage.getItem('token');

  let options = {
    'Content-Type': formdata ? 'multipart/form-data' : 'application/json',
  };

  if (token) {
    options.Authorization = `Token ${token}`;
  }
  return options;
};

export const errorHandler = (error) => {
  if (error.message === 'Network Error') {
    const errorMessage = 'Network Error! please check you internet connection';
    SwalAlert(errorMessage);
    return { error: errorMessage };
  } else {
    if (error.response) {
      const res = error.response.data;
      return res;
    } else {
      const err = JSON.stringify(error);
      SwalAlert(err);
      return { error: err };
    }
  }
};

export const getParams = (queryObj) => {
  if (!queryObj) return '';
  let params = '?';
  if (queryObj) {
    for (const [key, value] of Object.entries(queryObj)) {
      params = `${params}${key}=${value}&`;
    }
  }
  return params.slice(0, -1);
};

export const formdataConvertions = (payload) => {
  if (!payload) return '';
  const formdata = new FormData();
  if (payload) {
    for (const [key, value] of Object.entries(payload)) {
      formdata.append(key, value);
    }
  }
  return formdata;
};
