/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { Wrapper } from './style';

const Notification = ({ notification }) => {
  const [notif, setNotif] = useState(null);

  useEffect(() => {
    setNotif(notification);

    setTimeout(() => {
      setNotif(null);
    }, 3000);
  }, [notification]);

  // const { notification } = useSelector((state) => state.SharedStore);

  if (!notif?.text) return <></>;

  return (
    <Wrapper {...notif}>
      {notif.title && <div>{notif.title}</div>}
      <div>{notif.text}</div>
    </Wrapper>
  );
};

export default Notification;
