/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Wrapper = styled.div`
  color: white;
  width: 100%;
  display: flex;
  font-family: 'Montserrat';
  flex-direction: column;
  height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
  justify-content: center;
  font-size: 34px;
  font-weight: 400;
  font-family: 'Pilot Command Laser Super-Ital';
  color: white;
  height: 12vh;
`;

export const Paragraph = styled.ol`
  padding-left: 0;
  font-size: 12px;
  text-align: justify;
  white-space: pre-wrap;
  margin: 1.5rem;
`;
