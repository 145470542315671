/* eslint-disable prettier/prettier */
import React from 'react';
import { LogoSvg } from '../../../assets/svgs/index.js';
import { LineWrapper, Wrapper, ImageWrapper, RightLineWrapper, MainWrapper } from './styles.js';

const Header = () => {
  return (
    <MainWrapper>
      <Wrapper>
        <LineWrapper>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </LineWrapper>
        <ImageWrapper>
          <LogoSvg className="img-style" />
        </ImageWrapper>
        <RightLineWrapper>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </RightLineWrapper>
      </Wrapper>
    </MainWrapper>
  );
};

export default Header;
