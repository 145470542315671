/* eslint-disable prettier/prettier */
import React from 'react';
import { useEffect } from 'react';
import { ArrowBackSvg } from '@svgs';
import useHooks from '../useHooks';
import { Wrapper, TitleContainer, Paragraph } from './style';

const TermsAndConditions = ({ setPage }) => {
  const { termsandconditions, conditionsandterms } = useHooks();
  useEffect(() => {
    conditionsandterms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Wrapper>
      <TitleContainer>
        <ArrowBackSvg className="back-arrow" onClick={() => setPage('signup')} />
        <span className="title">TERMS AND CONDITIONS</span>
      </TitleContainer>
      <Paragraph dangerouslySetInnerHTML={{ __html: termsandconditions }} />
    </Wrapper>
  );
};

export default TermsAndConditions;
