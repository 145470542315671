/* eslint-disable prettier/prettier */
import { BASE_PATH_BACKEND, BASE_PATH } from 'utils/constants';
import { header, errorHandler } from './helpers';

const axios = require('axios');

const getRequest = async (api, paylaod, module) => {
  const apiLink = module ? `${BASE_PATH}/${api}` : `${BASE_PATH_BACKEND}/${api}`;
  const headers = await header();

  var config = {
    method: 'get',
    url: apiLink,
    data: paylaod,
    headers,
  };
  return await axios(config)
    .then((response) => {
      return response.data;
    })
    .catch(async (error) => {
      return errorHandler(error);
    });
};

const putRequest = async (api, payload) => {
  const apiLink = `${BASE_PATH_BACKEND}/${api}`;
  const headers = await header();
  const config = {
    method: 'PUT',
    url: apiLink,
    data: payload,
    headers,
  };
  return axiosCall(config);
};

const postRequest = async (api, payload, formdata) => {
  const apiLink = `${BASE_PATH_BACKEND}/${api}`;
  const headers = await header(formdata);

  const config = {
    method: 'POST',
    url: apiLink,
    data: payload,
    headers,
  };

  return axiosCall(config);
};

const axiosCall = async (config) => {
  return await axios(config)
    .then((response) => {
      return { success: response.data };
    })
    .catch(async (error) => {
      return errorHandler(error);
    });
};

export { getRequest, putRequest, postRequest };
