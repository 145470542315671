/* eslint-disable prettier/prettier */
import React from 'react';
import { useEffect } from 'react';
import { Layout } from '@components';
import useHooks from '@containers/Auth/useHooks';
import { Wrapper, Header, Paragraph } from './styles';

const TermsAndConditions = ({ setPage, page }) => {
  const { termsandconditions, conditionsandterms } = useHooks();
  useEffect(() => {
    conditionsandterms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout setPage={setPage} page={page}>
      <Wrapper>
        <Header>
          <span>TERMS AND CONDITIONS</span>
        </Header>

        <Paragraph dangerouslySetInnerHTML={{ __html: termsandconditions }} />
      </Wrapper>
    </Layout>
  );
};

export default TermsAndConditions;
