/* eslint-disable prettier/prettier */
import React from 'react';
import { useEffect } from 'react';
import { Layout } from '@components';
import useHooks from '@containers/Auth/useHooks';
import { Wrapper, Header, Paragraph } from './styles';

const PrivacyNotice = ({ setPage, page }) => {
  const { privacynotice, privacyNotice } = useHooks();
  useEffect(() => {
    privacynotice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout setPage={setPage} page={page}>
      <Wrapper>
        <Header>
          <span className="title"> PRIVACY NOTICE </span>
        </Header>
        <Paragraph dangerouslySetInnerHTML={{ __html: privacyNotice }} />
      </Wrapper>
    </Layout>
  );
};

export default PrivacyNotice;
