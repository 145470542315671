/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components/macro';
import { COLORS } from 'theme';

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  font-family: 'Montserrat';
  font-weight: 400;
  color: white;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2rem 0;
  justify-content: center;
  font-size: 34px;
  font-weight: 400;
  font-family: 'Pilot Command Laser Super-Ital';
  height: 12vh;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 88vh;
`;
export const CardContainer = styled.div`
  width: max(40%, 350px);
  margin: 1rem;
  .card-img {
    width: 100%;
    height: auto;
  }
  .room {
    padding: 1rem;
  }
`;
export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
`;
export const InnerWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  padding-top: 0;
  font-size: 16px;
`;

export const ItemField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;

  .input-row {
    border-bottom: ${({ noLine, error }) =>
      noLine ? '' : `1px solid ${error ? 'var(--error)' : 'grey'}`};
  }

  label {
    font-size: 11px;
    color: grey;
  }
  input {
    width: 100%;
    height: 100%;
    margin-top: 5px;
    color: white;
  }
  select {
    width: 100%;
    height: 100%;
    margin-top: 5px;
    background: transparent;
  }
  input[type='date'] {
    cursor: pointer;
    color-scheme: dark !important;
  }
`;

export const ItemFieldError = styled.div`
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  color: var(--error);

  @media only screen and (min-width: 280px) and (max-width: 450px) {
    font-size: 9px;
  }
`;

export const Button = styled.div`
  background: ${COLORS.theme_gradient};
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Orbitron';
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
`;

export const ActionsContainer = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  width: 100%;
  align-items: center;
  padding-bottom: 10px;
  ${({ active }) =>
    active
      ? css`
          color: rgba(242, 4, 81, 1);
        `
      : css`
          color: white;
        `}
`;
