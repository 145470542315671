/* eslint-disable prettier/prettier */
import React from 'react';
import { useState, useEffect } from 'react';
import { textValidator } from 'utils/validators';
import { Layout, DropDown, Confirm } from '@components';
import { ArrowRightSvg } from '@svgs';
import Card from '../card';
import { COUNTRIES, COUNTRIES_STATES } from './data';
import {
  MainWrapper,
  InnerWrapper,
  ItemField,
  ItemFieldError,
  Button,
  Header,
  Wrapper,
  CardContainer,
  CardWrapper,
  ActionsContainer,
  ActionsWrapper,
} from './style';
import useHook from './useHooks';

const Profile = ({ setPage, page }) => {
  const { updateProfile, userInfo: loginUserInfo, logout, deleteUser, getUserSystemId, getUserDetail } = useHook();
  const [action, setAction] = useState({ action: '' });
  const [systemIds, SetSystemIds] = useState([]);
  const [userInfo, setUserInfo] = useState({
    ...loginUserInfo,
    country: loginUserInfo.country || 'USA',
    state: loginUserInfo.state || COUNTRIES_STATES[loginUserInfo.country || 'USA'][0].value,
  });
  const [userInfoError, setUserInfoError] = useState({});

  useEffect(() => {
    const loadUserDetail = async () => {
      const res = await getUserDetail();
      if (res.status) {
       SetSystemIds(res.user_system);
       setUserInfo(res.user);
      }
    };
    loadUserDetail();
  }, []);

  const FIELDS = [
    {
      title: 'Email',
      name: 'email',
      placeholder: 'user@gmail.com',
      type: 'input',
      value: userInfo.email,
      disabled: true,
    },
    {
      title: 'First Name',
      name: 'first_name',
      placeholder: 'John',
      type: 'input',
      value: userInfo.first_name,
    },
    {
      title: 'Last Name',
      name: 'last_name',
      placeholder: 'Doe',
      type: 'input',
      value: userInfo.last_name,
    },
    {
      title: 'Date of Birth',
      name: 'dob',
      type: 'date',
      value: userInfo.dob,
      min: '01-01-1950',
      max: '12-12-2018',
      placeholder: 'mm-dd-yyyy',
    },
    {
      title: 'Country',
      name: 'country',
      placeholder: 'Us',
      type: 'dropdown',
      value: userInfo.country,
      options: COUNTRIES,
    },
    {
      title: 'State',
      name: 'state',
      placeholder: 'Texas',
      type: 'dropdown',
      value: userInfo.state,
      options: COUNTRIES_STATES[userInfo.country],
    },
    {
      title: 'City',
      name: 'city',
      placeholder: 'Enter a City',
      type: 'input',
      value: userInfo.city,
    },
    {
      title: 'Global User ID',
      name: 'userId',
      placeholder: 'Enter a City',
      type: 'input',
      value: userInfo.user_id ? userInfo.user_id : '-',
    },
  ];
  const actionsArr = ['Change Password', 'Delete Account', 'Log Out'];

  const OnClickActions = (element) => {
    setAction({ ...action, action: element });
    element === 'Change Password'
      ? setPage({ id: 'change-password' })
      : element === 'Delete Account'
      ? setAction({ ...action, deleteaccshow: true, action: element })
      : setAction({ ...action, logoutshow: true, action: element });
  };

  const onClose = () => setAction({ deleteaccshow: false, logoutshow: false });

  const logoutApp = () => {
    setAction({ ...action, logoutshow: false });
    logout();
  };

  const deleteAccount = () => {
    setAction({ ...action, deleteaccshow: false });
    deleteUser();
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    if (userInfoError[name]) {
      setUserInfoError({ ...userInfoError, [name]: '' });
    }
    setUserInfo({ ...userInfo, [name]: value });
  };

  const onDropdownSelect = (name, value) => {
    setUserInfo({ ...userInfo, [name]: value });
  };

  const validation = () => {
    const errors = {};
    let validation = true;
    if (textValidator(userInfo.first_name)) {
      errors.first_name = textValidator(userInfo.first_name, 'First Name');
      validation = false;
    }
    if (textValidator(userInfo.last_name)) {
      errors.last_name = textValidator(userInfo.last_name, 'Last Name');
      validation = false;
    }
    if (textValidator(userInfo.city)) {
      errors.city = textValidator(userInfo.city, 'City');
      validation = false;
    }
    if (!validation) {
      setUserInfoError(errors);
    }

    return validation;
  };

  const save = async () => {
    if (validation()) {
      const payload = { ...userInfo };

      delete payload.age_check;
      delete payload.id;
      delete payload.email;
      const res = await updateProfile(payload);
      if (res.success) {
        setPage('home');
      } else {
        setUserInfoError(res);
      }
    }
  };

  return (
    <Layout setPage={setPage} page={page}>
      <MainWrapper>
        <Header>PROFILE</Header>
        <Wrapper>
          <CardWrapper>
            <CardContainer>
              <Card title={'SIGN UP'}>
                <InnerWrapper>
                  {FIELDS.map((item, index) => {
                    return (
                      <div key={index}>
                        <ItemField noLine={item.name === 'email'} error={userInfoError[item.name]}>
                          <label>{item.title}</label>
                          <div className="input-row">
                            {(!item.type || item.type === 'input' || item.type === 'date') && (
                              <input {...item} onChange={onTextChange} />
                            )}
                            {item.type === 'dropdown' && (
                              <DropDown
                                width="100%"
                                valueKey="value"
                                onChange={(e) => onDropdownSelect([item.name], e.value)}
                                getLabel={(o) => o.label}
                                // searchable
                                // disabled={false}
                                style={{ marginTop: 5 }}
                                {...item}
                              />
                            )}
                          </div>
                          {userInfoError[item.name] && (
                            <ItemFieldError>{userInfoError[item.name]}</ItemFieldError>
                          )}
                        </ItemField>
                      </div>
                    );
                  })}
                  <div>
                    {systemIds?.length > 0 && systemIds?.map((item)=> (
                    <ItemField noLine={item?.name === 'email'} error={userInfoError[item?.name]}>
                    <label>{`(Room) ${item?.room} - (System) ${item?.title}`}</label>
                    <div className="input-row">
                        <input value={item?.user_system_id ? item?.user_system_id : '-'} disabled />
                    </div>
                  </ItemField>
                  ))}</div>

                  <Button onClick={save}>Save</Button>
                  <ActionsContainer>
                    {actionsArr.map((element, index) => {
                      const active = action.action === element;
                      return (
                        <ActionsWrapper
                          key={index}
                          active={active}
                          onClick={() => {
                            OnClickActions(element);
                          }}
                        >
                          <div>{element}</div>
                          <ArrowRightSvg />
                        </ActionsWrapper>
                      );
                    })}
                    <Confirm
                      visible={action?.deleteaccshow}
                      title="Are you sure you want to delete your account?"
                      onClose={onClose}
                      deleteButton={{
                        text: 'Yes',
                        action: deleteAccount,
                      }}
                    />
                    <Confirm
                      visible={action?.logoutshow}
                      title="Are you sure you want to Logout?"
                      onClose={onClose}
                      ok={{
                        text: 'Yes',
                        action: logoutApp,
                      }}
                    />
                  </ActionsContainer>
                </InnerWrapper>
              </Card>
            </CardContainer>
          </CardWrapper>
        </Wrapper>
      </MainWrapper>
    </Layout>
  );
};

export default Profile;
